import DropdownLink from "~/components/dropdown-link";
import { useLocationPusher } from "~/components/link";
import NavDropdown from "~/components/nav-dropdown";

interface Props {
  value: string;
  onChange?: (value: string) => void;
  redirect?: boolean;
}

export default function IntervalMenu({ value, onChange, redirect }: Props) {
  const push = useLocationPusher();
  const handleChange = (
    interval: string,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (redirect) {
      push({ interval });
    } else {
      onChange?.(interval);
    }
  };

  return (
    <NavDropdown label={`Interval: ${value}`}>
      {["Daily", "Weekly", "Monthly", "Quarterly", "Annual"].map((i) => (
        <DropdownLink
          key={i}
          match={value === i}
          to={(e) => handleChange(i, e)}
          label={i}
        />
      ))}
    </NavDropdown>
  );
}
